<script setup lang="ts">
import ExpressToggle from './ExpressToggle.vue';
import SortFilter from './SortFilter.vue';
import SizeFilter from './SizeFilter.vue';
import DiscountFilter from './DiscountFilter.vue';
import BrandFilter from './BrandFilter.vue';
import PriceFilter from './PriceFilter.vue';
import AllFilter from './AllFilter.vue';
import { useForm } from 'vee-validate';
import { useFilters } from '~/data/filters';
import StylesFilter from './StylesFilter.vue';
import DeliveryFilter from './DeliveryFilter.vue';
import { removeEmptyFilters } from './utils';
import { useRoute, useRouter } from 'vue-router';
import EidDeliveryToggle from './EidDeliveryToggle.vue';
import { getNodeIdFromHandle } from '~/utils/node-id-from-handle';
import OccasionsFilter from './OccasionsFilter.vue';
import FabricFilter from './FabricFilter.vue';
import ColorFilter from './ColorFilter.vue';
import SeasonsFilter from './SeasonsFilter.vue';
import { useGlobalStore } from '~/stores/global';
import { isNewFiltersGateEnabled } from '~/utils/featureFlags';
import { isMobileView } from '~/utils/helpers';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import {
	filterSchema,
	type FilterSchema,
	type FiltersResponseSchema,
} from '@laam/cms-shared';
import { toTypedSchema } from '@vee-validate/zod';
import { applyFilters, getFiltersFromUrl } from '@laam/ui';
import type { BannerReasons } from '../TopBanner.vue';
import { useDebounceFn } from '@vueuse/core';

const router = useRouter();
const route = useRoute();

const { storeType } = useGlobalStore();

const appliedFilterParams = ref<string>(route?.fullPath?.split('?')[1] || '');

interface FilterBarProps {
	handle?: string;
	class?: string;
	nodeIds?: Array<number>;
	static?: boolean;
	filtersFromProp?: string;
}
const props = defineProps<FilterBarProps>();

const getParams = computed(() => {
	if (route.path.includes('/brands') || route.path.includes('/drops')) {
		return props.handle ? { brand_handle: props.handle! } : {};
	} else if (route.path.includes('/node')) {
		return props.handle ? { node_id: getNodeIdFromHandle(props.handle!)! } : {};
	} else if (route.path.includes('/set')) {
		return {
			node_id: props.nodeIds!,
		};
	} else if (
		['identifier', 'men', 'kids', 'all-products'].includes(
			route.name!.toString(),
		)
	) {
		return props.nodeIds
			? {
					node_id: props.nodeIds?.join(',') ?? '',
				}
			: {};
	} else if (route.path.includes('/search')) {
		return props.handle ? { search_term: props.handle } : {};
	} else {
		return null;
	}
});

// get data filters data composable
const { data: filters, isLoading } = useFilters(getParams, appliedFilterParams);
const appliedFilters = ref<FilterSchema>();
const newFiltersEnabled = ref(false);

const isHeaderVisible = inject('headerVisible') as Ref<boolean>;

const hasCategories = (
	filter: FiltersResponseSchema[keyof FiltersResponseSchema],
) => {
	if (filter) {
		return !Object.values(filter).every(
			(category) =>
				!category ||
				Object.keys(category).length === 0 ||
				Object.keys(category) === null,
		);
	}
	return false;
};
// initial filters from url
const setFiltersFromUrl = () => {
	const url = window.location.href;
	const filtersFromUrl = getFiltersFromUrl(url);
	appliedFilters.value = filtersFromUrl;
	newFiltersEnabled.value = isNewFiltersGateEnabled() ?? false;
	if (props.filtersFromProp)
		appliedFilterParams.value += `&${props.filtersFromProp}`;
};

onMounted(() => {
	setFiltersFromUrl();
});

const { handleSubmit, setValues, validate } = useForm({
	validationSchema: toTypedSchema(filterSchema),
	initialValues: {
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
	},
	keepValuesOnUnmount: true,
});

watch(appliedFilters, (value) => {
	setValues({
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
		sort_by: undefined,
		price: undefined,
		...value,
	});
});

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	appliedFilterParams.value = route.fullPath.split('?')[1] || '';
}, 200);

watch(route, (route) => {
	if (
		!['brands', 'all-products', 'nodes', 'drops', 'identifier'].some((rn) =>
			route.name!.toString().includes(rn),
		)
	) {
		return;
	}
	debounce(route);
});

const onSubmit = (payload?: Event) => {
	payload?.preventDefault();
	handleSubmit(async (values) => {
		const url = window.location.href;
		const newFilters = removeEmptyFilters(values, filters.value!);
		// $eventClient.sendEvent('filter', {
		// 	filters: Object.keys(newFilters),
		// });
		const newPath = applyFilters(url, newFilters);
		// if we are on search page and we have search query
		if (route.path === '/search' && route.query.search) {
			const searchTerm = route.query.search;
			const nodeId = route.query.node_id;
			const [basePath, queryString] = newPath.split('?');
			if (queryString === '') {
				router.replace(`${basePath}?search=${searchTerm}`);
			} else {
				router.replace(
					`${basePath}?search=${searchTerm}&${queryString}${nodeId ? `&node_id=${nodeId}` : ''}`,
				);
			}
		} else {
			router.replace(newPath);
		}
	})();
};

const resetFilters = () => {
	setValues({
		brand: [],
		size: [],
		discount: [],
		delivery_days: [],
		node_id: [],
		attrs: [],
		sort_by: undefined,
		price: undefined,
	});
	onSubmit();
};

provide('filters', filters);
provide('validate', validate);
provide('newFiltersEnabled', newFiltersEnabled);
provide('setFiltersFromUrl', setFiltersFromUrl);

const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
const plpNewUi = inject('plpNewUi') as Ref<boolean>;
</script>
<template>
	<div v-if="isLoading">
		<FilterBarSkeleton />
	</div>
	<form
		v-else-if="filters"
		id="filterForm"
		class="z-[9] transition-all bg-white duration-75 filter_bar"
		:class="{
			static: static,
			sticky: !static,
		}"
		:data-store-type="storeType"
		:data-header-visible="isHeaderVisible"
		:data-top-banner="!!topBannerReason"
		@submit="onSubmit"
	>
		<div
			v-if="plpNewUi"
			class="p-3xl flex gap-md overflow-scroll no-scrollbar lg:px-none"
		>
			<AllFilter @reset-form="resetFilters" />
			<ExpressToggle @update:express-toggle="onSubmit" />
			<!-- Fabric Button -->
			<FabricFilter
				v-if="newFiltersEnabled && hasCategories(filters?.fabrics)"
			/>
			<!-- Price Button -->
			<PriceFilter />
			<!-- Size Button -->
			<SizeFilter v-if="hasCategories(filters?.size)" />
			<!-- Color Button -->
			<ColorFilter v-if="newFiltersEnabled" />
			<!-- Style Button -->
			<StylesFilter v-if="hasCategories(filters?.nodes)" />
			<!-- Brand Button -->
			<BrandFilter />
			<!-- Season Button -->
			<SeasonsFilter v-if="newFiltersEnabled" />
			<!-- Occasion Button -->
			<OccasionsFilter />
			<!-- Discount Button -->
			<DiscountFilter />
			<!-- Delivery Button -->
			<DeliveryFilter />
		</div>

		<div v-else class="py-md lg:py-3xl gap-md z-[8] flex flex-col">
			<div v-if="isMobileView()" class="gap-lg pl-3xl pr-md flex">
				<div class="grow">
					<ExpressToggle @update:express-toggle="onSubmit" />
				</div>
				<div class="gap-sm flex">
					<SortFilter />
					<AllFilter @reset-form="resetFilters" />
				</div>
			</div>

			<div class="lg:flex">
				<div class="lg:px-0 gap-md lg:gap-xl flex">
					<AllFilter v-if="!isMobileView()" @reset-form="resetFilters" />
					<!-- Sort Filter -->
					<div v-if="!isMobileView()">
						<SortFilter />
					</div>

					<div class="lg:block hidden border-r" />
				</div>
				<div class="gap-md px-3xl lg:gap-xl no-scrollbar flex overflow-scroll">
					<ExpressToggle
						v-if="!isMobileView()"
						@update:express-toggle="onSubmit"
					/>

					<EidDeliveryToggle v-if="!isMobileView()" />

					<!-- Fabric Button -->
					<FabricFilter
						v-if="newFiltersEnabled && hasCategories(filters?.fabrics)"
					/>
					<!-- Price Button -->
					<PriceFilter />
					<!-- Size Button -->
					<SizeFilter v-if="hasCategories(filters?.size)" />

					<!-- Color Button -->
					<ColorFilter v-if="newFiltersEnabled" />
					<!-- Style Button -->
					<StylesFilter v-if="hasCategories(filters?.nodes)" />
					<!-- Brand Button -->
					<BrandFilter />

					<!-- Season Button -->
					<SeasonsFilter v-if="newFiltersEnabled" />
					<!-- Occasion Button -->
					<OccasionsFilter />
					<!-- Discount Button -->
					<DiscountFilter />
					<!-- Delivery Button -->
					<DeliveryFilter />
				</div>
			</div>
		</div>
	</form>
</template>

<style scoped>
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='true'][data-top-banner='true'] {
	top: calc(
		var(--plp-header-height-mobile) + var(--laam-shipping-banner-height)
	);
	@media (min-width: 768px) {
		top: calc(
			var(--laam-topbar-height) + var(--laam-shipping-banner-height) +
				var(--plp-header-height)
		);
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='true'][data-top-banner='false'] {
	top: var(--plp-header-height-mobile);
	@media (min-width: 768px) {
		top: calc(var(--plp-header-height) + var(--laam-topbar-height));
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='false'][data-top-banner='true'] {
	top: var(--laam-shipping-banner-height);
	@media (min-width: 768px) {
		top: calc(var(--laam-topbar-height) + var(--laam-shipping-banner-height));
	}
}
.filter_bar[data-store-type='MARKETPLACE'][data-header-visible='false'][data-top-banner='false'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--laam-topbar-height);
	}
}
.filter_bar[data-store-type='OCTANE'][data-header-visible='true'] {
	top: var(--octane-topbar-height-mobile);
	@media (min-width: 768px) {
		top: calc(var(--plp-header-height) + var(--octane-topbar-height));
	}
}

.filter_bar[data-store-type='OCTANE'][data-header-visible='false'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--octane-topbar-height);
	}
}
</style>
