<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	DrawerDescription,
} from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
// import SizeFilterForm from './forms/SizeFilterForm.vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { AppliedFilters, SeasonFilterForm } from '@laam/ui';

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;
const setFiltersFromUrl = inject<() => void>('setFiltersFromUrl');

const show = computed(() => !!filters.value?.['attrs.season']);
const drawerToggle = ref(false);
const toggleDropdown = () => {
	drawerToggle.value = !drawerToggle.value;
};

const field = useField<string[] | undefined>('attrs');
const filterCount = computed(() => {
	const seasonFilters = field.value.value?.filter((item) =>
		item.includes('season'),
	);
	return seasonFilters?.length || 0;
});

const resetSeasonFilterForm = () => {
	toggleDropdown();
	field.setValue(
		field.value.value?.filter((element) => !element.includes('season')),
	);
};
const plpNewUi = inject('plpNewUi') as Ref<boolean>;
</script>
<template>
	<div v-if="show">
		<Drawer v-if="isMobileView()">
			<DrawerTrigger :as-child="true">
				<Button
					:size="'xs'"
					variant="secondary"
					class="season_filter_sheet_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					<div class="flex justify-center items-center gap-sm">
						<div
							v-if="!plpNewUi"
							class="flex py-xxs px-xs items-center rounded-[4px] bg-error-600"
						>
							<p class="text-white text-xxs font-semibold">New</p>
						</div>
						<span class="lg:px-xxs">
							{{ 'Season ' + (filterCount > 0 ? `(${filterCount})` : '') }}
						</span>
					</div>

					<PhCaretDown :weight="'bold'" />
				</Button>
			</DrawerTrigger>
			<DrawerContent
				side="bottom"
				class="season_filter_sheet_content max-h-[75%] pb-[150px]"
				@pointer-down-outside="setFiltersFromUrl"
			>
				<DrawerHeader>
					<DrawerTitle>Season</DrawerTitle>
				</DrawerHeader>
				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-7xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'season'" class="p-7xl pt-0" />

				<DrawerDescription class="p-3xl">
					<SeasonFilterForm />
				</DrawerDescription>
				<DrawerFooter
					class="absolute bottom-0 w-full bg-white left-none right-none"
				>
					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="primary"
							class="grow season_filter_apply"
							type="submit"
							form="filterForm"
						>
							{{ 'Apply ' + (filterCount > 0 ? `(${filterCount})` : '') }}
						</Button>
					</DrawerTrigger>

					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="secondary"
							type="submit"
							form="filterForm"
							class="size_filter_reset"
							@click="resetSeasonFilterForm"
						>
							Reset
						</Button>
					</DrawerTrigger>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>

		<DropdownMenu v-else v-model:open="drawerToggle">
			<DropdownMenuTrigger as-child>
				<Button
					:size="'md'"
					variant="secondary"
					class="season_filter_sheet_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					<div class="flex justify-center items-center gap-sm">
						<div
							v-if="!plpNewUi"
							class="flex py-xxs px-xs items-center rounded-[4px] bg-error-600"
						>
							<p class="text-white text-xxs font-semibold">New</p>
						</div>
						<span class="lg:px-xxs">
							{{ 'Season ' + (filterCount > 0 ? `(${filterCount})` : '') }}
						</span>
					</div>

					<PhCaretDown :weight="'bold'" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				class="w-[432px] !max-h-[500px] bg-white"
				@pointer-down-outside="setFiltersFromUrl"
			>
				<DropdownMenuLabel
					class="px-3xl py-3xl font-gray-800 text-xl font-semibold text-center"
				>
					Season
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-3xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'season'" class="p-3xl pt-0" />
				<DropdownMenuSeparator v-if="filterCount > 0" />
				<SeasonFilterForm class="p-7xl overflow-y-auto" />

				<DropdownMenuSeparator v-if="filterCount > 0" />

				<div
					class="px-3xl py-3xl flex items-center gap-md sticky bottom-0 w-full bg-white"
				>
					<Button
						size="lg"
						variant="primary"
						class="grow season_filter_apply bg-gray-800 w-full"
						type="submit"
						form="filterForm"
						@click="toggleDropdown()"
					>
						{{ 'Apply ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</Button>
					<Button
						size="lg"
						variant="secondary"
						type="submit"
						form="filterForm"
						class="season_filter_reset w-full"
						@click="resetSeasonFilterForm"
					>
						Reset
					</Button>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	</div>
</template>
